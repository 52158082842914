/** Colors **/
$grey: #1E183A;
$lightGrey: #C8C7DA;
$mediumGrey: #8F8FAC;
$mediumGreyText: #686868;
$tableHover: #EAEAFF;

$blue: #2196F3;
$red: #FF4646;
$green: #17B714;
$greenButton: #1FC143;
$greyButton: #B5B7C8;
$orange: #F36D21;

$black: #1A1B27;
$white: #FFFFFF;
$yellow: #F2B517;
$statusBgGrey: #ACACAC;