/******
 * Fonts
 **/

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/poppins/poppins-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
       url('fonts/poppins/poppins-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins/poppins-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins/poppins-v13-latin-600.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins/poppins-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins/poppins-v13-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/roboto/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('fonts/roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('fonts/roboto/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/roboto/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

//@font-face {
//  font-family: 'icomoon';
//  src:  url('fonts/icomoon/icomoon.eot?otl9xr');
//  src:  url('fonts/icomoon/icomoon.eot?otl9xr#iefix') format('embedded-opentype'),
//    url('fonts/icomoon/icomoon.ttf?otl9xr') format('truetype'),
//    url('fonts/icomoon/icomoon.woff?otl9xr') format('woff'),
//    url('fonts/icomoon/icomoon.svg?otl9xr#icomoon') format('svg');
//  font-weight: normal;
//  font-style: normal;
//  font-display: block;
//}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon/icomoon.eot?he87nf');
  src:  url('fonts/icomoon/icomoon.eot?he87nf#iefix') format('embedded-opentype'),
  url('fonts/icomoon/icomoon.ttf?he87nf') format('truetype'),
  url('fonts/icomoon/icomoon.woff?he87nf') format('woff'),
  url('fonts/icomoon/icomoon.svg?he87nf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vector-down:before {
  content: "\e900";
  color: #8f8fac;
}
.icon-vector-up:before {
  content: "\e901";
  color: #8f8fac;
}
.icon-sort:before {
  content: "\e902";
  color: #8f8fac;
}
.icon-vector-white:before {
  content: "\e903";
}
.icon-add:before {
  content: "\e924";
  color: #fff;
}
.icon-sortby:before {
  content: "\e923";
  color: #8f8fac;
}
.icon-arrowback:before {
  content: "\e904";
  color: #8f8fac;
}
.icon-edit:before {
  content: "\e910";
  color: #3e3f54;
}
.icon-trash:before {
  content: "\e921";
}

//.icon-add:before {
//  content: "\e924";
//  color: #fff;
//}
//.icon-sortby:before {
//  content: "\e923";
//  color: #8f8fac;
//}
//.icon-add-song:before {
//  content: "\e900";
//}
//.icon-arrowback:before {
//  content: "\e901";
//  color: #8f8fac;
//}
//.icon-arrow-down:before {
//  content: "\e902";
//}
//.icon-arrowright:before {
//  content: "\e903";
//}
//.icon-arrow-up:before {
//  content: "\e904";
//}
//.icon-bigsearch:before {
//  content: "\e905";
//}
//.icon-block:before {
//  content: "\e906";
//}
//.icon-checkbox-empty:before {
//  content: "\e907";
//}
//.icon-checkbox-fill:before {
//  content: "\e908";
//  color: #bdbfd9;
//}
//.icon-checkbox-unselect:before {
//  content: "\e909";
//  color: #bdbfd9;
//}
//.icon-client:before {
//  content: "\e90a";
//  color: #72777a;
//}
//.icon-connectivity:before {
//  content: "\e90b";
//  color: #72777a;
//}
//.icon-device:before {
//  content: "\e90c";
//  color: #72777a;
//}
//.icon-device-1:before {
//  content: "\e90d";
//  color: #72777a;
//}
//.icon-done:before {
//  content: "\e90e";
//  color: #2196f3;
//}
//.icon-downloading:before {
//  content: "\e90f";
//  color: #f36d21;
//}
//.icon-edit:before {
//  content: "\e910";
//  color: #3e3f54;
//}
//.icon-error:before {
//  content: "\e911";
//  color: #ff4646;
//}
//.icon-logo-vox:before {
//  content: "\e912";
//}
//.icon-name:before {
//  content: "\e913";
//  color: #72777a;
//}
//.icon-next-button:before {
//  content: "\e914";
//}
//.icon-pin:before {
//  content: "\e915";
//  color: #72777a;
//}
//.icon-play:before {
//  content: "\e916";
//  color: #2cdb5d;
//}
//.icon-playbutton:before {
//  content: "\e917";
//}
//.icon-radio-buttonempty:before {
//  content: "\e918";
//}
//.icon-radio-button-fill:before {
//  content: "\e919";
//}
//.icon-settings-3:before {
//  content: "\e91a";
//}
//.icon-sku:before {
//  content: "\e91b";
//  color: #72777a;
//}
//.icon-status:before {
//  content: "\e91c";
//  color: #72777a;
//}
//.icon-stop:before {
//  content: "\e91d";
//  color: #ff4646;
//}
//.icon-stop-button:before {
//  content: "\e91e";
//}
//.icon-storage:before {
//  content: "\e91f";
//  color: #72777a;
//}
//.icon-timezone:before {
//  content: "\e920";
//  color: #72777a;
//}
//.icon-trash:before {
//  content: "\e921";
//}
//.icon-validate-big:before {
//  content: "\e922";
//  color: #2cdb5d;
//}

